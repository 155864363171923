
//main

$base-font: "lato",sans-serif;
$font-desktop: 20px;
$font-mobile: 16px;
$base-color: #004854;
$base-white: #FFFFFF;
$breakpoint-resp-menu: 1099px;

//colors
$light-blue: #48afc4;
$lighter-blue: #f2f6f6;
$dark-blue: #002a36;
$btn-blue: #76c3d3;
$border-grey: #f2f2f2;
$font-grey: #7a7a79;
$bg-megamenu: #e8ebeb;
$bg-slide: #f2f5f5;
