@charset "UTF-8";
.margin-0 {
  margin: 0; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-30 {
  margin-bottom: 15px; }
  @media (min-width: 769px) {
    .margin-bottom-30 {
      margin-bottom: 15px; } }

.margin-bottom-50 {
  margin-bottom: 25px; }
  @media (min-width: 769px) {
    .margin-bottom-50 {
      margin-bottom: 25px; } }

.margin-bottom-40 {
  margin-bottom: 20px; }

.margin-bottom-60 {
  margin-bottom: 30px; }

.margin-bottom-70 {
  margin-bottom: 35px; }

.margin-bottom-120 {
  margin-bottom: 60px; }

.margin-bottom-30-sm-down {
  margin-bottom: 30px; }
  @media (min-width: 769px) {
    .margin-bottom-30-sm-down {
      margin-bottom: 0; } }

.margin-bottom-0 {
  margin-bottom: 0; }

.margin-top-negative-30 {
  margin-top: -30px; }

.margin-top-0 {
  margin-top: 0; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-40 {
  margin-top: 20px; }

.margin-top-50 {
  margin-top: 25px; }

.margin-top-60 {
  margin-top: 30px; }

@media (min-width: 769px) {
  .padding-right-90-md-up {
    padding-right: 45px; } }

@media all and (max-width: 768px) {
  .no-padding-top-sm-down {
    padding-top: 0; } }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.font-size-26 {
  font-size: 13px; }
  @media (min-width: 769px) {
    .font-size-26 {
      font-size: 26px; } }

@media (min-width: 769px) {
  .font-size-35 {
    font-size: 35px !important; } }

.font-size-20 {
  font-size: 20px; }

.font-size-50 {
  font-size: 50px !important; }

.light-weight {
  font-weight: 300; }

.semi-weight {
  font-weight: 600; }

.align-right-on-xs {
  text-align: right; }
  @media (min-width: 769px) {
    .align-right-on-xs {
      text-align: left; } }

.center-content {
  text-align: center; }

.text-light {
  font-weight: 300; }
  .text-light strong {
    font-weight: 700; }

@media (min-width: 1400px) {
  .col-xxl-3 {
    width: 25%; } }

.user-form-wrap {
  max-width: 600px;
  margin: 30px auto; }
  .user-form-wrap .user-field {
    margin-bottom: 25px; }
  .user-form-wrap input[type='submit'][value="Vybrat předdefinovaný avatar"] {
    margin-top: 15px; }

.js-upload-styles .uploader-input-file {
  display: none; }

.js-upload-styles .fake-upload-wrapper label, .js-upload-styles .fake-upload-wrapper .uploader {
  margin-bottom: 55px;
  position: relative;
  width: 100%; }

.js-upload-styles .fake-upload-wrapper .fake-upload {
  height: 115px;
  width: 100%;
  position: absolute;
  top: -55px;
  left: 0; }
  .js-upload-styles .fake-upload-wrapper .fake-upload__text {
    width: 100%;
    height: 50px;
    overflow: hidden;
    display: block;
    border: 1px solid #f2f2f2;
    background: #f2f2f2;
    padding: 9px 20px 13px;
    line-height: 30px;
    text-align: center;
    color: #48afc4;
    margin-top: 30px; }
  .js-upload-styles .fake-upload-wrapper .fake-upload__button {
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    padding: 14px 25px 12px 15px;
    margin-bottom: 15px;
    text-align: center;
    background: #48afc4;
    color: #FFFFFF;
    line-height: 22px;
    top: 17px;
    position: relative;
    border: 1px solid #48afc4;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms;
    line-height: 18px; }
    .js-upload-styles .fake-upload-wrapper .fake-upload__button:hover {
      background: #004854;
      border-color: #004854; }

@media (min-width: 769px) {
  .js-upload-styles .fake-upload-wrapper label {
    margin-bottom: 80px; }
  .js-upload-styles .fake-upload-wrapper .fake-upload {
    height: 50px; }
    .js-upload-styles .fake-upload-wrapper .fake-upload__text {
      width: auto;
      margin-top: 0; }
    .js-upload-styles .fake-upload-wrapper .fake-upload__button {
      width: 50%;
      margin-left: 0;
      float: right;
      margin-bottom: 0;
      height: 50px;
      position: static; } }

.user-table {
  margin-bottom: 0 !important; }

.btn.create-user {
  margin: 30px 0 0 -20px; }

table td .dropdown-menu > li > input {
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #FFFFFF;
  height: auto; }
