@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.user-form-wrap {
  max-width: 600px;
  margin: 30px auto;

  .user-field {
    margin-bottom: 25px;
  }

  input[type='submit'][value="Vybrat předdefinovaný avatar"] {
    margin-top: 15px;
  }
}

.js-upload-styles {
  .uploader-input-file {
    display: none;
  }

  .fake-upload-wrapper {

   label, .uploader {
     margin-bottom: 55px;
     position: relative;
     width: 100%;
   }

  .fake-upload {
      height: 115px;
      width: 100%;
      position: absolute;
      top: -55px;
      left: 0;

      &__text {
          width: 100%;
          height: 50px;
          overflow: hidden;
          display: block;
          border: 1px solid $border-grey;
          background: $border-grey;
          padding: 9px 20px 13px;
          line-height: 30px;
          text-align: center;
          color: $light-blue;
          margin-top: 30px;
      }

      &__button {
          width: 100%;
          min-height: 50px;
          cursor: pointer;
          padding: 14px 25px 12px 15px;

          margin-bottom: 15px;
          text-align: center;
          background: $light-blue;
          color: $base-white;
          line-height: 22px;
          top: 17px;
          position: relative;
          border: 1px solid $light-blue;
          @include transition(150ms);
          line-height: 18px;

          &:hover {
            background: #004854;
            border-color: #004854;
          }
      }
    }


    @include media(md) {

        label {
            margin-bottom: 80px;
        }

        .fake-upload {
            height: 50px;

            &__text {
                width: auto;
                margin-top: 0;
            }

            &__button {
                width: 50%;
                margin-left: 0;
                float: right;
                margin-bottom: 0;
                height: 50px;
                position: static;
            }
        }
    }
  }
}

.user-table {
  margin-bottom: 0 !important;
}

.btn.create-user {
  margin: 30px 0 0 -20px;
}

table td .dropdown-menu > li > input {
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #FFFFFF;
    height: auto;
}
